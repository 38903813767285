import React from "react";
import { SContainer } from "./styles";
import { Grid, Container } from "@mui/material";
import Logo from "../../assets/images/canow.svg";

export default function Header() {
  return (
    <SContainer maxWidth={false} disableGutters>
      <Container maxWidth="lg">
        <Grid
          container
          sx={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          <Grid item>
            <img src={Logo} alt="" height={50} />
          </Grid>
        </Grid>
      </Container>
    </SContainer>
  );
}
