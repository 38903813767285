import { Box, Button, Container, Typography } from '@mui/material';
import styled from 'styled-components';

export const CustomBox = styled(Box)`
    &&{
        background: var(--primary);
        border-radius: 8px;
        box-shadow: 0px 1px 3px var(--gray);
        padding:32px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: max-content;
        margin: auto;
        @media (max-width: 768px) {
            padding: 16px;
        }
    }
`;

export const CustomText = styled(Typography)`
    &&{
        font-size: 24px;
        font-family: Poppins Medium;
        color: var(--light);
        max-width: 461px;
        margin-bottom: 32px;
        text-align: center;
    }
`;

export const CustomButton = styled(Button)`
&&{
    background: var(--light);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 26px;
    box-shadow: 0px 1px 3px var(--gray);
    font-family: Poppins Medium;
    font-size: 20px;
    color: var(--primary);
    height: 48px;
    text-transform: none;
    &:hover{
        background: #f5f5f5;
    }
}
`;

export const CustomContainer = styled(Container)`
    &&{
        padding-bottom: 259px;
        background: var(--light);
        @media (max-width: 768px) {
            padding-bottom: 128px;
        }
    }
`;