import styled from "styled-components";
import { Box, Container, Typography } from "@mui/material";

export const CustomContainer = styled(Container)`
  && {
    padding-top: 293px;
    padding-bottom: 326px;
    background: var(--light);
    @media (max-width: 768px) {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 46px;
    color: var(--primary);
    margin-left: 96.5px;
    @media (max-width: 1200px) {
      margin-bottom: 64px;
    }
    @media (max-width: 768px) {
      font-size: 32px;
      margin-left: 0px;
      margin-bottom: 64px;
    }
  }
`;

export const CustomBox = styled(Box)`
  && {
    max-width: 1037px;
    max-height: 572px;
    height: 572px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    @media (max-width: 768px) {
      max-height: 530px;
      justify-content: start;
    }
  }
`;

export const CustomBoxInside = styled(Box)`
  && {
    max-width: 675px;
    max-height: 351px;
    height: 351px;
    width: 675px;
    border: 6px solid var(--primary);
    border-radius: 8px;
    background: var(--light);
    z-index: -1;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const CustomBoxText = styled(Box)`
  && {
    max-width: 502px;
    box-shadow: 0px 1px 3px var(--primary);
    border-radius: 8px;
    padding: 32px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    background: var(--light);
    z-index: 1;
    @media (max-width: 768px) {
      padding: 16px;
      left: 0;
    }
  }
`;

export const CustomBoxText2 = styled(Box)`
  && {
    max-width: 502px;
    box-shadow: 0px 1px 3px var(--primary);
    border-radius: 8px;
    padding: 32px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--light);
    z-index: 1;
    @media (max-width: 768px) {
      padding: 16px;
    }
  }
`;

export const CustomTitleBox = styled(Typography)`
  && {
    font-size: 24px;
    font-family: Poppins Semibold;
    color: var(--primary);
    margin-bottom: 16px;
  }
`;

export const CustomTextBox = styled(Typography)`
  && {
    font-size: 16px;
    font-family: Poppins Regular;
    color: var(--dark);
    margin-bottom: 8px;
  }
`;

export const CustomContactBox = styled(Box)`
  && {
    background: var(--light-3);
    border-radius: 8px;
    padding: 64px;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px var(--gray);
    max-width: 823px;
    @media (max-width: 768px) {
      padding: 16px;
    }
  }
`;

export const CustomContactTitle = styled(Typography)`
  && {
    font-size: 32px;
    font-family: Poppins Semibold;
    color: var(--primary);
    margin-bottom: 16px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

export const CustomContactText = styled(Typography)`
  && {
    font-size: 24px;
    font-family: Poppins Regular;
    color: var(--dark);
    margin-bottom: 64px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;
