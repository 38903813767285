import {
  Container
} from "@mui/material";
import styled from "styled-components";

export const SContainer = styled(Container)
`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding-top:20px;
`;
