import { Container, Grid } from '@mui/material'
import React from 'react'
import Header from "../../layouts/Header2";
import {CustomText,CustomTitle,CustomButton} from './style';

export default function Privacy() {
  return (
    <>
    <Container maxWidth="false" sx={{background:'var(--light)'}}>
    <Header/>
        <Container maxWidth="lg" sx={{padding:'128px 0px'}}>
            <Grid container>
                <Grid item xs={12} md={10}>
                    <CustomTitle>Política de Privacidade</CustomTitle>
                    <CustomText>
                        A J.CANÃO, LDA adota medidas para proteger a privacidade dos Clientes e Utilizadores do seu website, bem como dos seus produtos e serviços, tendo elaborado a presente Política de Privacidade com o propósito de demonstrar o seu compromisso e respeito pelo Regulamento Geral de Proteção de Dados (RGPD).
                        Algumas das funcionalidades do website da J.CANÃO, LDA podem ser utilizadas sem recurso à divulgação de qualquer informação pessoal por parte do utilizador.            
                    </CustomText><br/>
                    <CustomText>Os dados pessoais são recolhidos quando tal seja consentido pelo respetivo titular, e a disponibilização dos dados pessoais pelo titular implica o conhecimento e aceitação das condições constantes desta Política de Privacidade, pelo que, ao disponibilizar os seus dados pessoais, o Utilizador está a autorizar a recolha, uso e divulgação dos mesmos de acordo com as regras definidas nos instrumentos de recolha aplicáveis.</CustomText><br/>
                    <CustomText>Os dados pessoais recolhidos destinam-se à utilização da J.CANÃO, LDA., podendo, quando autorizado pelo titular ser utilizados para a comunicação com os Clientes, processamento de pedidos de informação, pedidos de assistência, análise estatística, promoções e ações de marketing direto.</CustomText><br/>
                    <CustomText>Os seus dados pessoais podem ser comunicados a prestadores de serviços da J.Canão, subcontratados ou terceiros, para efeitos da prestação dos serviços, e a autoridades judiciais, fiscais e regulatórias, com a finalidade do cumprimento de imposições legais.</CustomText><br/>
                    <CustomText>A J.CANÃO, LDA não divulga a terceiros quaisquer dados pessoais dos seus Clientes e Utilizadores, sem o seu consentimento, exceto quando tal seja necessário para que os colaboradores, funcionários, fornecedores ou parceiros comerciais da J.CANÃO, LDA possam fornecer um produto ou serviço ou executar uma função em nome da J.CANÃO, LDA, ou para cumprimento de ordem judicial ou de autoridade competente nos termos do previsto na legislação em vigor.</CustomText><br/>
                    <CustomText>A J.CANÃO, LDA guarda os dados dos seus clientes e Utilizadores nos seus servidores localizados em Portugal. Estes são protegidos e mantidos de acordo com elevados níveis de segurança, acautelando a proteção dos dados pessoais que são disponibilizados pelos seus Clientes e/ou Utilizadores, contra a sua eventual utilização abusiva ou contra o acesso não autorizado. No entanto, a J.CANÃO, LDA não pode fornecer uma garantia absoluta relativamente aos dados do Utilizador.</CustomText><br/>
                    <CustomText>A J.CANÃO, LDA adotará as precauções razoáveis para garantir que os seus funcionários ou colaboradores com acesso a dados pessoais recebam formação adequada ao seu correto processamento, com respeito pela presente política e pelas obrigações legais de proteção de dados. No caso de incumprimento, a J.CANÃO, LDA aplicará sanções disciplinares aos seus funcionários e colaboradores.</CustomText><br/>
                    <CustomText>Sempre que a J.CANÃO, LDA divulgar os dados pessoais do Utilizador salvaguardará o cumprimento do Regulamento Geral de Proteção de Dados, nomeadamente através da previsão de disposições contratuais que garantam que o terceiro utiliza os dados recebidos somente para as finalidades especificadas, e em conformidade com os fins descritos nesta política, e que faz uso de meios de segurança adequados, de forma a proteger os dados pessoais do Utilizador contra tratamentos ilegais ou não autorizados bem como contra a sua perda acidental, destruição ou outras ações danosas.</CustomText><br/>
                    <CustomText>O website da J.CANÃO, LDA pode conter links para outros websites e endereços de e-mail de empresas não pertencentes à J.CANÃO, LDA. A presente Política de Privacidade não se aplica a esses websites e endereços de e-mail.</CustomText><br/>
                    <CustomText>A J.CANÃO, LDA não partilha cookies com terceiros, incluindo fornecedores externos de dados ou websites.</CustomText><br/>
                    <CustomText>A J.CANÃO, LDA reserva o direito de alterar a presente Política de Privacidade em qualquer altura. Quaisquer alterações serão publicadas nesta Política de Privacidade e as alterações materiais aplicar-se-ão às atividades e dados recolhidos no futuro. Em conformidade com o Regulamento Geral de Proteção de Dados, o Utilizador tem direito a obter informações sobre os seus dados pessoais. Tem o direito de saber que dados pessoais foram processados pela J.CANÃO, LDA, podendo também requerer para corrigir, complementar, bloquear ou eliminar os dados total ou parcialmente no caso de se revelarem incompletos, incorretos ou irrelevantes para efeitos de processamento.</CustomText><br/>
                    <CustomText>Caso deseje, a qualquer momento, deixar de fazer parte da base de dados J.CANÃO, LDA poderá exercer esse direito, contactando-nos através dos seguintes meios:</CustomText><br/>
                    <CustomText><b>Email:</b>jcanao@jcanao.pt</CustomText>
                    <CustomText><b>Carta:</b>J.Canão, Lda. – Avenida de Cabo Verde, Lote 5 – 4900-568 Viana do Castelo</CustomText>
                    <CustomText><b>Telefone:</b>258 800 600</CustomText>
                    <CustomText><b>Site:</b>www.jcanao.pt</CustomText>
                </Grid>
                <Grid item xs={0} md={2} align="end" sx={{display:{xs:'none', md:'block'}}}>
                    <a href="/" style={{textDecoration:'none'}}>
                    <CustomButton>Voltar</CustomButton>
                    </a>
                </Grid>
            </Grid>
        </Container>
    </Container>
    </>
  )
}
