import React from "react";
import {
  CustomContainer,
  CostumBox,
  CustomSubTitle,
  CustomListItem,
  Img,
} from "./style.js";
import { Container, Grid, List, ListItemIcon } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRightRounded";
import Emissoes from "../../../assets/images/emissoes.svg";

export default function Section7() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            md={5}
            lg={6}
            display={"flex"}
            alignItems={"center"}
            sx={{ display: { xs: "none", lg: "flex" } }}
          >
            <Img src={Emissoes} alt="" />
          </Grid>
          <Grid item xs={0} lg={1}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CostumBox>
              <CustomSubTitle>Emissões e Interligações</CustomSubTitle>
              <List
                sx={{
                  padding: 0,
                  "& .MuiListItemIcon-root": {
                    color: "var(--primary)",
                    width: "30px",
                    minWidth: "30px",
                    display: "flex",
                    justifyContent: "end",
                    "& svg": {
                      fontSize: "40px",
                    },
                  },
                }}
              >
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Emissão Automática das Guias de Receita das Taxas Cobradas
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Emissão e controlo do processo de emissão de Concessões
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Emissão de guias de receita para as várias taxas do cemitério
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Exportação das Guias de Receita
                </CustomListItem>
              </List>
            </CostumBox>
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
