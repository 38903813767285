import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import data from "../../data/footer.json";
import { STypographyTitle, STypographyDesc } from "./styles";
import AdditionalInfo from "./components/AdditionalInfo";
import Facebook from "../../assets/images/facebook.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import Certificacoes from "../../assets/images/certifs.svg";

const iconMapping = {
  "facebook":Facebook,
  "linkedin":Linkedin,
}

const imageMapping = {
  "certificacoes":Certificacoes,
}

export default function Footer() {
  return (
    <>
      <Container maxWidth={false} sx={{ background: "var(--light-3)", padding:'158px 0px'}}>
        <Container maxWidth="lg">
          <Grid container display={'flex'} justifyContent={'space-between'} sx={{gap:{xs:'32px', lg:'0px'}}}>
            {Object.entries(data).map(([title, items]) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={2.5}
                key={title}
              >
                <STypographyTitle>{title}</STypographyTitle>
                {Array.isArray(items)
                  ? items.map((item) => (
                      <Link to={item.link} key={item.text} style={{textDecoration:'none'}} target="_blank">
                        {item.icon === "facebook" || item.icon === "linkedin" ? (
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              width:'fit-content',
                              gap:'16px'
                            }}
                          >
                            <img
                              src={iconMapping[item.icon]}
                              alt={item.icon}
                              style={{padding:'0px', width:'max-content', height:'max-content' }}
                            />
                            <STypographyDesc key={item}>{item.text}</STypographyDesc>
                          </div>
                        ) : item.text === "(chamada para rede fixa nacional)" ? (
                          <STypographyDesc
                            key={item}
                            style={{ fontSize: "9px", cursor:'default' }}
                          >
                            {item.text}
                          </STypographyDesc>
                        ) : 
                        item.image === "certificacoes" ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              flexDirection: "column",
                              alignItems: "start",
                              cursor:'default'
                            }}
                          >
                            <img
                              src={imageMapping[item.image]}
                              style={{maxWidth:'100%', height:'auto'}}
                              alt=""
                            />
                            <STypographyDesc
                              key={item}
                              style={{ fontSize: "9px", marginTop: "5px" }}
                            >
                              {item.text}
                            </STypographyDesc>
                          </Box>
                        ) : (
                          <STypographyDesc key={item} fontWeight="bold">
                            {item.text === "Googlemaps" ? <strong>{item.text}</strong> : item.text}
                          </STypographyDesc>
                        )}
                      </Link>
                    ))
                  : ""}
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
      <AdditionalInfo />
    </>
  );
}
