import React from "react";
import {
  CustomContainer,
  CustomTitle,
  CustomBox,
  CustomBoxInside,
  CustomBoxText,
  CustomTitleBox,
  CustomTextBox,
  CustomBoxText2,
  CustomContactTitle,
  CustomContactText,
  CustomContactBox,
} from "./style";
import { Container } from "@mui/material";
import ButtonBlue from "../../ui/button/bluebutton";

export default function Section10() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <CustomTitle>Mais Vantagens</CustomTitle>
        <CustomBox>
          <CustomBoxText>
            <CustomTitleBox>
              Armazene documentos e fotos com segurança
            </CustomTitleBox>
            <CustomTextBox>
              Armazene digitalizações de escrituras, licenças, cartões, etc;
            </CustomTextBox>
            <CustomTextBox>
              Adicione fotos de lápides ou memoriais;
            </CustomTextBox>
          </CustomBoxText>
          <CustomBoxInside />
          <CustomBoxText2>
            <CustomTitleBox>
              Proteja seus dados com backups automáticos
            </CustomTitleBox>
            <CustomTextBox>
              Todos os dias são realizados backups para garantir a redução de
              possíveis perdas de informação;
            </CustomTextBox>
            <CustomTextBox>
              Gerado em formatos de arquivo portáteis JSON e CSV.
            </CustomTextBox>
          </CustomBoxText2>
        </CustomBox>
      </Container>
      <Container
        maxWidth="lg"
        sx={{ mt: "286px", display: "flex", justifyContent: "center" }}
      >
        <CustomContactBox>
          <CustomContactTitle>Entre em contacto connosco</CustomContactTitle>
          <CustomContactText>
            Se tiver alguma dúvida, precisar de informações adicionais ou quiser
            discutir sobre os nossos serviços, não hesite em contactar.
          </CustomContactText>
          <ButtonBlue>Saber mais</ButtonBlue>
        </CustomContactBox>
      </Container>
    </CustomContainer>
  );
}
