import styled from 'styled-components';
import BG from "../../../assets/images/bgwrapper.png";
import { Container, Typography } from '@mui/material';

export const CustomContainer = styled(Container)`
    &&{ 
        background-image: url(${BG});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        padding-bottom: 258px;
        @media (max-width: 768px) {
            padding-bottom: 128px;
        }
    }
`;

export const CustomTitle = styled(Typography)`
    &&{
        font-family:Poppins Medium;
        font-size: 55px;
        color: var(--primary);
        margin-bottom:8px;
        @media (max-width: 768px) {
            font-size: 32px;
        }
    }
`;
export const CustomSubTitle = styled(Typography)`
    &&{
        font-family:Poppins Regular;
        font-size: 44px;
        color: var(--dark);
        margin-bottom:32px;
        @media (max-width: 768px) {
            font-size: 24px;
        }
    }
`;
export const CustomText = styled(Typography)`
    &&{
        font-family:Poppins Regular;
        font-size: 24px;
        color: var(--dark);
        margin-bottom:32px;
        max-width: 716px;
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
`;