import styled from 'styled-components';
import { Container, Typography } from '@mui/material';

export const CustomContainer = styled(Container)`
    &&{
        padding-top:246px;
        padding-bottom:211px;
        background: var(--light-2);
        @media (max-width: 768px) {
            padding-top: 128px;
            padding-bottom: 128px;
        }
    }
`;

export const CustomTitle = styled(Typography)`
    &&{
        font-family: Poppins Semibold;
        font-size: 46px;
        color: var(--primary);
        margin-bottom:64px;
        text-align:center;
        @media (max-width: 768px) {
            font-size: 32px;
        }
    }
`;
export const CustomSubTitle = styled(Typography)`
    &&{
        font-family: Poppins Medium;
        font-size: 24px;
        color: var(--primary);
        margin-bottom:16px;
    }
`;
export const CustomText = styled(Typography)`
    &&{
        font-family: Poppins Regular;
        font-size: 20px;
        color: var(--dark);
    }
`;