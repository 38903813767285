import React from "react";
import {
  CustomContainer,
  CustomTitle,
  CustomText,
  CustomSubTitle,
} from "./style";
import { Container } from "@mui/material";
import Header from "../../../layouts/Header";
import ButtonBlue from "../../ui/button/bluebutton";

export default function Section1() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <Header />
        <CustomTitle>Recupere o Passado e Construa o Futuro</CustomTitle>
        <CustomSubTitle>
          Consultoria de Gestão de Registos de Cemitérios
        </CustomSubTitle>
        <CustomText>
          Revitalize a gestão dos registos cemiteriais com a nossa solução de
          consultoria inovadora. Recuperamos, preservamos e otimizamos o
          histórico dos registos do seu cemitério. A nossa equipa de
          especialistas digitaliza, cataloga e organiza os registos com
          eficiência e segurança.
        </CustomText>
        <ButtonBlue>Saber mais</ButtonBlue>
      </Container>
    </CustomContainer>
  );
}
