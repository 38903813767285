import React from "react";
import {
  CustomContainer,
  CustomTitle,
  CustomText,
  CustomSubTitle,
} from "./style.js";
import { Container, Grid } from "@mui/material";
import ButtonBlue from "../../ui/button/bluebutton/ButtonBlue.js";

export default function Section3() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomTitle>Benefícios</CustomTitle>
          </Grid>
          <Grid container item xs={12} spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <CustomSubTitle>Gestão centralizada</CustomSubTitle>
              <CustomText>
                Permite gerir múltiplos cemitérios num único sistema,
                simplificando a gestão global. Ajuda na tomada de decisões.
              </CustomText>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomSubTitle>Rastreio documental</CustomSubTitle>
              <CustomText>
                Armazena e acompanha toda a documentação essencial, como
                alvarás, contratos e registos de propriedade, facilitando a
                organização e o acesso rápido às informações.
              </CustomText>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomSubTitle>Relatório e análises</CustomSubTitle>
              <CustomText>
                Oferece relatório detalhados e análises estatísticas sobre a
                ocupação do cemitério, concessões ativas, movimentos dos
                defuntos e outros dados relevantes.
              </CustomText>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomSubTitle>Segurança e confidencialidade</CustomSubTitle>
              <CustomText>
                Garanta a segurança de dados e a confidencialidade das
                informações dos cemitérios e dos titulares das concessões.
              </CustomText>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomSubTitle>Suporte técnico e atualizações</CustomSubTitle>
              <CustomText>
                Suporte técnico dedicado para auxiliar os utilizadores, além de
                disponibilizar atualizações de forma a manter o sistema
                atualizado e funcional.
              </CustomText>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomSubTitle>Integração com contabilidade</CustomSubTitle>
              <CustomText>
                Controlo dos pagamentos, contratos, taxas anuais de forma rápida
                e eficaz. Contabilidade POCAL.
              </CustomText>
            </Grid>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <ButtonBlue>Saber mais</ButtonBlue>
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
