import React from "react";
import { SContainer } from "./styles";
import { Grid, Container } from "@mui/material";
import Logo from "../../assets/images/canow.svg";

export default function Header() {
  return (
    <SContainer maxWidth={false} disableGutters>
      <Container maxWidth="lg" disableGutters>
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginBottom: { xs: "128px", lg: "272px" },
          }}
        >
          <Grid item>
            <img src={Logo} alt="" height={60} />
          </Grid>
        </Grid>
      </Container>
    </SContainer>
  );
}
