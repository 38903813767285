import React, { useState } from 'react'
import { CustomButton } from './style'
import ModalContact from "../../../forms/modalcontact";

export default function ButtonBlue({children}) {

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  }

  const handleClose = () => {
    setOpenModal(false);
  }

  return (
    <>
    <CustomButton onClick={handleOpen}>{children}</CustomButton>
    {openModal &&( 
    <ModalContact openModal={openModal} handleClose={handleClose} />
    )}
    </>
  )
}
