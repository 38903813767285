import { Container, Grid } from '@mui/material'
import React from 'react'
import Header from "../../layouts/Header2";
import {CustomText,CustomTitle,CustomButton,CustomSubTitle} from './style';

export default function Cookies() {
  return (
    <>
    <Container maxWidth="false" sx={{background:'var(--light)'}}>
    <Header/>
        <Container maxWidth="lg" sx={{padding:'128px 0px'}}>
            <Grid container>
                <Grid item xs={12} md={10}>
                    <CustomTitle>Política de Cookies</CustomTitle>
                    <CustomSubTitle>O que são Cookies?</CustomSubTitle>
                    <CustomText>
                    Os cookies são pequenos ficheiros de texto enviados para o seu computador ou dispositivo móvel quando visita websites. Os cookies ajudam os websites a armazenar e recuperar informação sobre os seus hábitos de navegação por forma a personalizar a navegação de acordo com as suas preferências​           
                    </CustomText><br/>
                    <CustomSubTitle>Que tipos de cookies utiliza o nosso website?</CustomSubTitle>
                    <CustomText>Cookies de sessão: são cookies temporárias que permanecem em arquivo de cookies do navegador até que este abandone a página web, pelo que não fica qualquer registo no disco rígido do utilizador. A Informação recolhida por estes cookies servem para analisar padrões de tráfego do site. Em última análise, isso permite-nos melhorar o conteúdo e melhorar a usabilidade do site.</CustomText><br/>
                    <CustomText>Cookies de análise: São aqueles que bem tratados por nós ou por terceiros, nos permitem quantificar o número de utilizadores e realizar a medição e análise estatística de como os utilizadores usam o serviço oferecido. Examinamos a sua navegação no nosso site, com o objetivo de melhorar o fornecimento dos serviços que lhe disponibilizamos.</CustomText><br/>
                    <CustomText>Cookies publicitárias: São aqueles que tratadas por nós ou por terceiros, nos permitem gerir de uma forma mais eficaz a oferta de espaços publicitários que existem no site, podendo analisar os seus hábitos de navegação e mostrar-lhe publicidade relacionada com o seu perfil de navegação.</CustomText><br/>
                    <CustomSubTitle>Quais são os cookies que utilizamos no nosso site?</CustomSubTitle>
                    <CustomText><b>Cookies Próprios:</b></CustomText>
                    <CustomText> - Cookies de sessão;</CustomText>
                    <CustomText>- Armazenam a informação de categoria para mostrar as páginas de forma mais rápida;</CustomText>
                    <CustomText>- Indicam se está atualmente logado no site;</CustomText>
                    <CustomText>- ID's de sessão no servidor;</CustomText>
                    <CustomText>- Informações sobre pesquisas;</CustomText>
                    <CustomText>- Indicam se o utilizador autoriza o uso de cookies.</CustomText>
                    <br/>
                    <CustomText><b>Cookies de Terceiros:</b></CustomText>
                    <CustomText>- Utilizados para distinguir utilizadores e sessões;</CustomText>
                    <CustomText>- Utilizadas para determinar novas visitas, sessões;</CustomText>
                    <CustomText>- Armazenam a fonte de tráfego para explicar como o utilizador chega ao site;</CustomText>
                    <CustomText>- Utilizam-se para identificar utilizadores e prevenir usos fraudulentos de dados de utilizadores e proteger dados de utilizadores de terceiros;</CustomText>
                    <CustomText>- Utilizadas para personalizar publicidade em redes de Publisher;</CustomText>
                    <CustomText>- Permitem ao site recordar preferências de navegação, idioma, região.</CustomText>
                    <br/>
                    <CustomText><b>Outros:</b></CustomText>
                    <CustomText>- Analíticas web e vídeos;</CustomText>
                    <CustomText>- Determina que servidor mostra a informação ao utilizador;</CustomText>
                    <CustomText>- - Segue as sessões ativas;</CustomText>
                    <CustomText>- Determina se as cookies estão ativas;</CustomText>
                    <CustomText>- Mede o tempo de resposta dos utilizadores;</CustomText>
                    <CustomText>- Identifica quantas vezes nos visitou um utilizador,</CustomText>
                    <CustomText>- Permitem saber se é a primeira visita ao site ou não.</CustomText>
                    <br/>
                    <CustomSubTitle>O que implica não autorizar o uso de cookies?</CustomSubTitle>
                    <CustomText>Se preferir não permitir cookies tem a possibilidade de desativar os cookies no navegador de internet (browser) que utiliza, mas deverá ter em conta que poderá impedir que algumas páginas Web sejam apresentadas corretamente.</CustomText>
                </Grid>
                <Grid item xs={0} md={2} align="end" sx={{display:{xs:'none', md:'block'}}}>
                    <a href="/" style={{textDecoration:'none'}}>
                        <CustomButton>Voltar</CustomButton>
                    </a>
                </Grid>
            </Grid>
        </Container>
    </Container>
    </>
  )
}
