import { Container, Typography } from '@mui/material';
import styled from 'styled-components';

export const CustomContainer = styled(Container)`
    &&{
        padding-top: 309px;
        padding-bottom: 136px;
        background:var(--light);
        @media (max-width: 768px) {
            padding-top: 128px;
            padding-bottom: 128px;
        }
    }
`;

export const CustomTitle = styled(Typography)`
    &&{
        font-family:Poppins Semibold;
        font-size: 46px;
        color: var(--primary);
        margin-bottom: 32px;
        @media (max-width: 768px) {
            font-size: 32px;
        }
    }
`;

export const CustomText = styled(Typography)`
    &&{
        font-family:Poppins Regular;
        font-size: 24px;
        color: var(--dark);
        max-width: 716px;
        margin-bottom: 32px;
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
`;