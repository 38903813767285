import React from "react";
import {
  CustomContainer,
  CustomTitle,
  CostumBox,
  CustomSubTitle,
  CustomListItem,
  Img,
} from "./style.js";
import { Container, Grid, List, ListItemIcon } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRightRounded";
import Funcionalidades from "../../../assets/images/funcionalidades.svg";

export default function Section4() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} marginBottom={"64px"}>
            <CustomTitle>Funcionalidades</CustomTitle>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CostumBox>
              <CustomSubTitle>Gestão & Controlo</CustomSubTitle>
              <List
                sx={{
                  padding: 0,
                  "& .MuiListItemIcon-root": {
                    color: "var(--primary)",
                    width: "30px",
                    minWidth: "30px",
                    display: "flex",
                    justifyContent: "end",
                    "& svg": {
                      fontSize: "40px",
                    },
                  },
                }}
              >
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Gestão do espaço das sepulturas por sublocais de inumações
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Utilitário de configuração da planta do cemitério e disposição
                  das sepulturas
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Identificação e registo de defuntos
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Registo da realização de inumações, exumações e transladações
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon color="var(--primary)" />
                  </ListItemIcon>
                  Histórico dos defuntos
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Controlo de validade das concessões
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Processo de mudança de proprietário e sucessão de herdeiros
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Pesquisa e acesso rápida de informação
                </CustomListItem>
              </List>
            </CostumBox>
          </Grid>
          <Grid item xs={0} lg={1}></Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={6}
            display={"flex"}
            alignItems={"center"}
            sx={{ display: { xs: "none", lg: "flex" } }}
          >
            <Img src={Funcionalidades} alt="" />
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
