import './assets/css/App.css';
import Routes from './routes/routes.js';
import Footer from "./layouts/Footer";
function App() {

  return (
   <>
    <Routes/>
    <Footer/>
   </>
  );
}

export default App;
