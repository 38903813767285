import { Container, Typography } from '@mui/material';
import styled from 'styled-components';

export const CustomContainer = styled(Container)`
    &&{
        padding-top:143px;
        padding-bottom: 186px;
        background: transparent linear-gradient(58deg, #FFFFFF 0%, #FFFFFFDB 36%, #FFFFFFDB 80%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
        @media (max-width: 768px) {
            padding-top: 128px;
            padding-bottom: 128px;
            background: var(--light);
        }
    }
`;

export const CustomTitle = styled(Typography)`
    &&{
        font-size: 46px;
        font-family: Poppins Semibold;
        color: var(--primary);
        margin-bottom: 64px;
        @media (max-width: 768px) {
            font-size: 32px;
        }
    }
`;

export const CustomSubTitle = styled(Typography)`
    &&{
        font-size: 24px;
        font-family: Poppins Semibold;
        color: var(--dark);
        margin-bottom: 16px;
        @media (max-width: 768px){
            text-align: center;
        }
    }
`;

export const CustomText = styled(Typography)`
    &&{
        font-size: 20px;
        font-family: Poppins Regular;
        color: var(--dark);
        @media (max-width: 768px){
            text-align: center;
        }
    }
`;
