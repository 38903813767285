import { Container } from '@mui/material'
import React, {useState} from 'react'
import { CustomBox,CustomButton,CustomText,CustomContainer } from './style'
import ModalContact from "../../forms/modalcontact";

export default function Section8() {

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  }

  const handleClose = () => {
    setOpenModal(false);
  }

  return (
    <CustomContainer maxWidth="false" disableGutters >
        <Container maxWidth="lg">
            <CustomBox>
                <CustomText>Conheça todas as funcionalidades que temos para oferecer!</CustomText>
                <CustomButton onClick={handleOpen}>Contactar</CustomButton>
                {openModal &&( 
                  <ModalContact openModal={openModal} handleClose={handleClose} />
                )}
            </CustomBox>
        </Container>
    </CustomContainer>
  )
}
