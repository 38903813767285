import React from "react";
import {
  CustomContainer,
  CustomTitle,
  CustomSubTitle,
  CustomText,
} from "./style";
import Number1 from "../../../assets/images/1.svg";
import Number2 from "../../../assets/images/2.svg";
import Number3 from "../../../assets/images/3.svg";
import Number4 from "../../../assets/images/4.svg";
import { Container, Grid } from "@mui/material";

export default function Section9() {
  return (
    <CustomContainer maxWidth="false">
      <Container maxWidth="lg">
        <CustomTitle>Processo</CustomTitle>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={1.5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginBottom: { xs: "32px", lg: "" } }}
          >
            <img src={Number1} alt="" />
          </Grid>
          <Grid item xs={12} sm={8} md={5.5} lg={5.5}>
            <CustomSubTitle>Analisar mapas e registos</CustomSubTitle>
            <CustomText>
              Reunir todos os mapas e registos existentes para revisão, análise
              e organização da informação de maneira eficiente.
            </CustomText>
          </Grid>
        </Grid>
        <Grid container marginTop={"64px"}>
          <Grid item xs={0} sm={0} md={1.5}></Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={1.5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginBottom: { xs: "32px", lg: "" } }}
          >
            <img src={Number2} alt="" />
          </Grid>
          <Grid item xs={12} sm={8} md={5.5} lg={5.5}>
            <CustomSubTitle>Criar um mapa base</CustomSubTitle>
            <CustomText>
              Elaborar um mapa aéreo claro e conciso do cemitério.
            </CustomText>
          </Grid>
        </Grid>
        <Grid container marginTop={"64px"}>
          <Grid item xs={0} sm={0} md={3}></Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={1.5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginBottom: { xs: "32px", lg: "" } }}
          >
            <img src={Number3} alt="" />
          </Grid>
          <Grid item xs={12} sm={8} md={5.5} lg={5.5}>
            <CustomSubTitle>Criar os talhões</CustomSubTitle>
            <CustomText>
              Estabelecer todas as áreas e divisões existentes no cemitério,
              criando os talhões correspondentes de forma precisa e organizada.
            </CustomText>
          </Grid>
        </Grid>
        <Grid container marginTop={"64px"}>
          <Grid item xs={0} sm={0} md={4.5}></Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={1.5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ marginBottom: { xs: "32px", lg: "" } }}
          >
            <img src={Number4} alt="" />
          </Grid>
          <Grid item xs={12} sm={8} md={5.5} lg={5.5}>
            <CustomSubTitle>Convergir informações</CustomSubTitle>
            <CustomText>
              Para concluir, unificar todos os registos de acordo com as
              divisões previamente estabelecidas, garantindo assim uma gestão
              consolidada e coerente das informações.
            </CustomText>
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
