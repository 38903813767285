import React from "react";
import {
  CustomContainer,
  CostumBox,
  CustomSubTitle,
  CustomListItem,
  Img,
} from "./style.js";
import { Container, Grid, List, ListItemIcon } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRightRounded";
import MapasListagens from "../../../assets/images/mapaslistagens.svg";

export default function Section6() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CostumBox>
              <CustomSubTitle>Mapas & Listagens</CustomSubTitle>
              <List
                sx={{
                  padding: 0,
                  "& .MuiListItemIcon-root": {
                    color: "var(--primary)",
                    width: "30px",
                    minWidth: "30px",
                    display: "flex",
                    justifyContent: "end",
                    "& svg": {
                      fontSize: "40px",
                    },
                  },
                }}
              >
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Mapa de Receitas
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Listagem dos Defuntos no Cemitério
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Listagem dos Responsáveis
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Listagem dos funcionários do Cemitério
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Listagem das Concessões
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Listagem dos Proprietários
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Listagem de Movimentos de Defuntos
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Listagem de Locais Vagos
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Edital de Exumações
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Guia de Inumação
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Requerimento de Transladação
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Requerimento de Inumação e Cremação
                </CustomListItem>
                <CustomListItem>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  Mapas de Editais
                </CustomListItem>
              </List>
            </CostumBox>
          </Grid>
          <Grid item xs={0} lg={1}></Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={6}
            display={"flex"}
            alignItems={"center"}
            sx={{ display: { xs: "none", lg: "flex" } }}
          >
            <Img src={MapasListagens} alt="" />
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
