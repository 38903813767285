import React from 'react'
import {CustomContainer,CustomText,CustomTitle} from './style'
import { Container, Grid } from '@mui/material'
import ButtonBlue from '../../ui/button/bluebutton'
import Woman from "../../../assets/images/woman.png";

export default function Section2() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <Grid container display={'flex'} alignItems={'center'}>
          <Grid item xs={12} md={6} lg={7}>
            <CustomTitle>Porque passar a digital?</CustomTitle>
            <CustomText>Um sistema digital garante eficiência na organização e o armazenamento dos registos do cemitério. Ganha um acesso rápido e fácil a todos os registos que necessita, simplificando todo o processo não só de pesquisa como de análise, sendo que permite retirar relatórios em tempo real de forma a analisar toda a situação em tempo real. Além disso, garante cópias de segurança que protegem de potenciais perdas de informação.</CustomText>
            <ButtonBlue>Saber mais</ButtonBlue>
          </Grid>
          <Grid item xs={12} md={6} lg={5} display={'flex'} alignItems={'center'} sx={{justifyContent:{xs:'center', lg:'end'}, marginTop:{xs:'64px', lg:'0px'},display:{xs:'none', md:'block'} }}>
            <img src={Woman} alt="" style={{maxWidth:'100%', height:'auto'}} />
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  )
}
