import styled from "styled-components";
import { Box, Container, ListItem, Typography } from "@mui/material";

export const CustomContainer = styled(Container)`
  && {
    background: var(--light);
    padding-bottom: 172px;
    @media (max-width: 1280px) {
      padding-bottom: 128px;
    }
  }
`;

export const CustomSubTitle = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 32px;
    color: var(--primary);
    margin-bottom: 16px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

export const CostumBox = styled(Box)`
  && {
    border-radius: 8px;
    background: var(--white);
    box-sizing: border-box;
    box-shadow: 0px 0px 6px #2f83ff;
    padding: 32px;
    @media (max-width: 1280px) {
      padding: 32px 16px;
      width: 692px;
    }
  }
`;

export const CustomListItem = styled(ListItem)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--black);
    padding: 0px;
  }
`;

export const Img = styled.img`
  && {
    max-width: 100%;
    height: auto;
    @media (max-width: 1100px) {
      display: none;
    }
  }
`;
