import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from "../pages/Home";
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms&Conditions';
import Cookies from '../pages/Cookies';

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/terms-conditions" element={<Terms/>} />
            <Route path="/cookies" element={<Cookies/>} />
        </Routes>
    )
}

export default AppRoutes
