import styled from 'styled-components';
import { Button } from '@mui/material';

export const CustomButton = styled(Button)`
    &&{
        background: var(--primary);
        border-radius: 8px;
        box-sizing: border-box;
        padding: 10px 26px;
        box-shadow: 0px 1px 3px var(--gray);
        font-family: Poppins Medium;
        font-size: 20px;
        color: var(--light);
        height: 48px;
        text-transform: none;
        &:hover{
            background: #2874E3;
        }
    }
`;